<template>
  <div>
    <v-row>
      <v-col cols="12" md="12">
        <div class="text-center">
          <p class="text-h3">
            Proyectos de Inversión

          </p>
        </div>
      </v-col>

    </v-row>
    <v-row no-gutters>
      <v-col cols="12" md="3" v-for="(p, i) in projetsList" class="contenedor" :key="i">
        <div >
          <v-img :src="p.image" class="imagen"></v-img>
        </div>
      </v-col>
    </v-row>

  </div>
</template>

<script>
export default {
  data: () => ({
    projetsList: [
      { id: 1, title: "A", image: require('@/assets/img/project/1.jpg') },
      { id: 1, title: "A", image: require('@/assets/img/project/2.jpg') },
      { id: 1, title: "A", image: require('@/assets/img/project/3.jpg') },
      { id: 1, title: "A", image: require('@/assets/img/project/4.jpg') },
      { id: 1, title: "A", image: require('@/assets/img/project/5.jpg') },
      { id: 1, title: "A", image: require('@/assets/img/project/6.jpg') },
      { id: 1, title: "A", image: require('@/assets/img/project/7.jpg') },
      { id: 1, title: "A", image: require('@/assets/img/project/8.jpg') },
      { id: 1, title: "A", image: require('@/assets/img/project/9.jpg') },
      { id: 1, title: "A", image: require('@/assets/img/project/10.jpg') },
      { id: 1, title: "A", image: require('@/assets/img/project/11.jpg') },
      { id: 1, title: "A", image: require('@/assets/img/project/12.jpg') },
      { id: 1, title: "A", image: require('@/assets/img/project/13.jpg') },
      { id: 1, title: "A", image: require('@/assets/img/project/14.jpg') },
      { id: 1, title: "A", image: require('@/assets/img/project/15.jpg') },
      { id: 1, title: "A", image: require('@/assets/img/project/16.jpg') },
      { id: 1, title: "A", image: require('@/assets/img/project/17.jpg') },
      { id: 1, title: "A", image: require('@/assets/img/project/18.jpg') },
      { id: 1, title: "A", image: require('@/assets/img/project/19.jpg') },
      { id: 1, title: "A", image: require('@/assets/img/project/20.jpg') },
      { id: 1, title: "A", image: require('@/assets/img/project/21.jpg') },
      { id: 1, title: "A", image: require('@/assets/img/project/22.jpg') },
    ]
  })
};
</script>

<style lang="scss" scoped>

  .imagen:hover {filter: blur(5px);}
</style>