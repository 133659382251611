<template>
  <!-- <v-row>
  <div class="video-player">
    <video class="video" ref="video" loop autoplay height="600">
      <source :src="require('@/assets/video/bsc.mp4')" type="video/mp4" />
    </video>
  </div>
  </v-row> -->

  <VideoParallax height="300" :src="require('@/assets/video/bsc.mp4')">
    <p class="text-h2 white--text"></p>
  </VideoParallax>


</template>

<script>
import VideoParallax from "vuetify-video-parallax";

export default {
  components: {
    VideoParallax,
  },
  data: () => ({
    // https://servrsc.com/bsc_data/bsc_vd.mp4
  }),

  computed: {
    videoElement () {
      return this.$refs.video;
    }, 
  }
};
</script>

<style lang="scss" scoped>
.video-player{
  left: 0;
  right: 0;
  margin: 0;
  width: 100%;
}
</style>