import axios from 'axios';

const API_URL = process.env.VUE_APP_SERVICES_HOST;


class RepositoryService {
    post(payload, entity) {
        console.log("SERVICES POST WEB PAGE")
        return axios.post(`${API_URL}${entity}`, payload);
    }

    get(entity){
        return axios.get(`${API_URL}${entity}`);
    }
}

export default new RepositoryService();