<template>
    <div class="container-card-eud">
      <v-card
          class="mx-auto my-12"
          max-width="374"
      >

        <v-img
            height="250"
            src="https://cdn.vuetifyjs.com/images/cards/cooking.png"
        ></v-img>

        <v-card-title>Cafe Badilico</v-card-title>

        <v-card-text>
          <v-row
              align="center"
              class="mx-0"
          >
            <div class="grey--text ms-4">
              4.5 (413)
            </div>
          </v-row>

          <div class="my-4 text-subtitle-1">
            $ • Italian, Cafe
          </div>

          <div>Small plates, salads & sandwiches - an intimate setting with 12 indoor seats plus patio seating.</div>
        </v-card-text>

        <v-divider class="mx-4"></v-divider>

        <v-card-title>Tonight's availability</v-card-title>

        <v-card-text>
          <v-chip-group
              v-model="selection"
              active-class="deep-purple accent-4 white--text"
              column
          >
            <v-chip>5:30PM</v-chip>

            <v-chip>7:30PM</v-chip>

            <v-chip>8:00PM</v-chip>

            <v-chip>9:00PM</v-chip>
          </v-chip-group>
        </v-card-text>

        <v-card-actions>
          <v-btn
              color="deep-purple lighten-2"
              text
              @click="reserve"
          >
            Reserve
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>
</template>

<script>
export default {
  name: "CardImage",
  props: {
    imageSrc: null,
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.container-card-eud{
    border-block-start-style: none;
}
.container-card-eud:hover{
    border-bottom-style: solid;
    border-bottom-color: #f26723;
    border-bottom-width: 3px;
}
</style>