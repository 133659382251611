<template>
  <v-container fluid class="footer-container">
    <v-row class="pa-7">
      <v-col cols="12" md="3" class="info-section">
        <p class="text-subtitle-1"> Télefono</p>
        <p class="text-body-1 text-left"><i class="mdi mdi-phone"></i> Lunes a Viernes de 9 a 18 hrs </p>
        <p class="text-body-3 text-center">993 402 7847
        </p>
      </v-col>
      <v-col cols="12" md="3" class="info-section">
        <p class="text-subtitle-1"> Nuestras Sucursales</p>
        <p class="text-body-2"><i class="mdi mdi-map-marker"></i> Encuentra una sucursal cerca de ti</p>
        <p class="text-body-3 text-center">Calle Sabancuy 102 Colonia Jose Colomo
          <br>
          CP 86030, Villahermosa Tabasco
        </p>
      </v-col>


      <v-col cols="12" md="3" class="info-section">
        <p class="text-subtitle-1"> Solicita información</p>
        <p class="text-body-3"><i class="mdi mdi-email-outline"></i> Envianos un email para mas información o para aclaraciones</p>
      </v-col>
      <v-col cols="12" md="3" class="info-section">
        <p class="text-subtitle-1"> Folletos digitales</p>
        <p class="text-body-3"><i class="mdi mdi-file-table"></i> Consulta los folletos informativos</p>
      </v-col>
      <v-col cols="12" md="12">
        <v-divider dark></v-divider>
      </v-col>
    </v-row>
    <v-row no-gutters>

      <v-col cols="12" md="3" class="pa-5">
        <v-img src="@/assets/logos/logo-tr.png" alt="footer logo" width="250"/>
        <span class="text-subtitle-2 text-left">Tenemos el conocimiento, la experiencia y las tecnologías para garantizar que todos nuestros trabajos contaran con la cálidad y eficiencia que su empresa requiere para alcanzar el éxito de los proyectos encomendados.</span>
      </v-col>
      <v-col cols="12" md="4" class="pa-5">
        <v-list dark class="text-left">
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>Servicios</v-list-item-title>
              <v-list-item-subtitle>
                Conoce la amplia gama de servicios financieros para las Pymes y grandes empresas.
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>Proyectos de Inversión</v-list-item-title>
              <v-list-item-subtitle>
               Tenemos a tu alcance planes de financiamiento reales, ágiles y competitivos.
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>Terminos & Condiciones</v-list-item-title>
              <v-list-item-subtitle>
                Conoce la amplia gama de servicios financieros para las Pymes y grandes empresas.
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>Aviso de privacidad</v-list-item-title>
              <v-list-item-subtitle>
                Tu seguridad y confidencialidad de tus datos los tomamos muy enserio.
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>Soluciones digitales</v-list-item-title>
              <v-list-item-subtitle>
                Conoce la amplia gama de servicios financieros para las Pymes y grandes empresas.
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>

      </v-col>
      <v-col cols="12" md="5" class="pa-5">
        <div class="single-footer-widget">
          <h3>Newsletter</h3>
          <p>No olvides suscribirte para recibir noticias, promociones e infromación, por favor llene
            el siguiente formulario.
            .</p>
          <form>
            <input type="email" placeholder="Email">
            <button type="submit"><i class="far fa-envelope"></i></button>
          </form>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.footer-container {
  background-color: #1e1e1e;
  color: #c7baba;
}

.mdi {
  font-size: 30px;
  margin-right: 5px;
}

.far{
  font-size: 18px;
}

.single-footer-widget li {
  margin-bottom: 5px
}

.single-footer-widget li a:hover {
  color: #eee
}

.single-footer-widget>form {
  background: #333 none repeat scroll 0 0;
  height: 35px;
  margin-top: 10px;
  position: relative;
}

.single-footer-widget input {
  background: transparent none repeat scroll 0 0;
  border: medium none;
  height: 100%;
  left: 0;
  padding: 5px 10px;
  position: absolute;
  top: 0;
  color: #eee;
  width: 80%;
}

.single-footer-widget button {
  background: rgba(89, 5, 5, 0.78) none repeat scroll 0 0;
  border: medium none;
  color: #fff;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 20%;
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s
}

.single-footer-widget button:hover {
  background: #464646 none repeat scroll 0 0
}
</style>