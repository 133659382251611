import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import es from 'vuetify/es5/locale/es';
import '@mdi/font/css/materialdesignicons.css'; 

Vue.use(Vuetify);

export default new Vuetify({ 
    icons: {
        iconfont: 'mdi', // default - only for display purposes
    },
    lang: {
        locales: { es },
        current: 'es',
    },
    theme: {
        themes: {
            light: {
                primary: '#000000',
                secondary: '#EBF2F2',
                palet: '#F2F2F2',
                accent: '#F2A7A7',
                error: '#f44336',
                warning: '#FF9800',
                info: '#2196F3',
                success: '#4caf50',
                grey200: '#b5b5b5',
                grey400: '#666666',
                grey600: '#3c3c3c',
                black600: '#3a3a3a',
                activeColor: '#F21D2F'
            },
        },
    },
});
