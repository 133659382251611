<template>
  <v-card >
    <div class="single-service">
      <div class="service-icon text-h5">
        <v-img :src="require('@/assets/logos/logo-tr.png')" width="200" contain></v-img>
      </div>
      <div class="cont-ic">
        <h3>
          <a>{{ data.title }}</a>
        </h3>
        <p class="pa-5" v-if="data.description">
          {{ data.description }}
        </p>
        <ul v-if="data.list && data.list.length > 0" class="pa-5">
          <li v-for="(l, i) in data.list" :key="i">
            {{l}}
          </li>
        </ul>
        <div class="bg-ic">
          <i :class="data.icon "></i>
        </div>
      </div>


    </div>
  </v-card>

</template>

<script>
export default {
  name: "CardService",
  props: {
    data: {},
  }
};
</script>

<style lang="scss" scoped>
.single-service {
  height: 300px;
  background: #fff none repeat scroll 0 0;
  font-weight: 300;
  line-height: 24px;
  margin-bottom: 30px;
  margin-top: 45px;
  padding: 40px 15px 30px;
  position: relative;
  text-align: center;

  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.single-service .service-icon {
  background-color: #fff;
  border-radius: 50%;
  box-sizing: border-box;
  height: 80px;
  left: 0;
  line-height: 80px;
  margin: -42px auto 0;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
  width: 80px;
}

.single-service .service-icon i:before {
  font-size: 30px;
  margin-left: 0;
  color: #000000 ;
}

.single-service:before {
  background: #000000  none repeat scroll 0 0;
  content: "";
  height: 40px;
  left: 0;
  position: absolute;
  top: 0;
  width: 4px;
}

.single-service:after {
  background: #000000  none repeat scroll 0 0;
  content: "";
  height: 4px;
  left: 0;
  position: absolute;
  top: 0;
  width: 40px;
}

.single-service > h3 {
  font-size: 19px;
  margin-bottom: 10px;
  text-transform: uppercase;
  color: #333;
}

.single-service > h3 > a:hover {
  color: #000000 ;
}

.cont-ic{
  height: 100%;
  position: relative;
  overflow: hidden;
}

.bg-ic{
  color: rgba(255, 255, 255, 0.97);
  position: absolute;
  left: -10px;
  bottom: -6px;
  font-size: 90px;
  opacity: 0.1;
}
ul {
  list-style-image: url('@/assets/img/chevron-right.png');
  z-index: 20;
}
li {
  text-align: justify !important;
color: #aaaaaa;
}

li:last-child {
  border-left: 0px solid #000000 ;
}
</style>