<template>
    <div class="bleezy-count-area pa-10">
        <v-row>
            <v-col cols="12" md="4">
              <v-card height="250" elevation="2"
                  color="primary"
                  dark
              >
                <v-card-title class="text-h5">
                 Misión
                </v-card-title>

                <v-card-text class="text-justify">Satisfacer la necesidad de nuestros clientes a través de un servicio excelente y de máxima calidad que brinde todas las ventajas financieras para el éxito de sus proyectos.</v-card-text>

              </v-card>
            </v-col>
          <v-col cols="12" md="4">
            <v-card height="250" elevation="2"
                    color="primary"
                    dark
            >
              <v-card-title class="text-h5">
                Visión
              </v-card-title>

              <v-card-text class="text-justify">Ser el principal socio estratégico de empresas Mexicanas con proyectos de crecimiento, basados en el mejoramiento continuo y ofreciendo estándares de alta calidad en nuestro servicio..</v-card-text>

            </v-card>
          </v-col>
          <v-col cols="12" md="4">
            <v-card height="250" elevation="2"
                    color="primary"
                    dark
            >
              <v-card-title class="text-h5">
                Valores
              </v-card-title>

              <v-card-text class="text-justify">
                <ul v-if="list && list.length > 0" class="pa-5">
                  <li v-for="(l, i) in list" :key="i">
                    {{l}}
                  </li>
                </ul>
              </v-card-text>

            </v-card>
          </v-col>
        </v-row>
    </div>
</template>
<script>
export default {
data:()=>({
  list: [
      "Compromiso",
      "Honestidad",
      "Integridad",
      "Confianza",
      "Lealtad ",
      "Responsabilidad"
      ]
})
};
</script>
<style scoped>
ul {
  list-style-image: url('@/assets/img/chevron-right.png');
  z-index: 20;
}
li {
  text-align: justify !important;
  color: #aaaaaa;
}

li:last-child {
  border-left: 0px solid #f26723;
}
</style>