<template>
    <div class="container-card-eud">
        <v-card>
          <v-img
            height="250"
            width="100%"
            :src="imageSrc"
          ></v-img>
          <v-card-text>
            <v-row align="center" class="mx-0">
              <div class="grey--text ms-4">{{title}}</div>
            </v-row>
            <div>
             {{description}}
            </div>
          </v-card-text>
        </v-card>
    </div>
</template>

<script>
export default {
  name: "CardImage",
  props: {
    imageSrc: null,
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.container-card-eud{
    border-block-start-style: none;
}
.container-card-eud:hover{
    border-bottom-style: solid;
    border-bottom-color: #f26723;
    border-bottom-width: 3px;
}
</style>