import Repository from '../services/repository.services'
const PREFIX = 'system/';

const state = {
    success: null
}

const getters = {
    getSucces: (state)=> state.success
}

const actions = {
    sendMessage({ commit }, data){
        console.log("SERVICIO DE ENVIO DE MENSAJE")
        return Repository.post(data.item, `${PREFIX}${data.entity}`).then(
            response =>{
                commit('SET_SUCCESS', true);
                return Promise.resolve(response.status);
            },
            error=>{
                commit('SET_SUCCESS', false);
                return Promise.reject(error);
            });
    }
}

const mutations = {
    SET_SUCCESS(state, rs) {
        state.success = rs;
    },
}

export const web_page = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}