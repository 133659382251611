<template>
  <div class="mt-30">
    <Carousel />

    <v-container fluid class="section-who pt-7 pb-7">
      <v-row class="d-flex justify-center">
        <v-col cols="12" md="12">
         <div class="container-bsc-who d-flex justify-center">
           <div class="img-container-who mr-n12 mt-15">
             <v-img class="img-who" :src="require('@/assets/img/main.jpg')" width="280"></v-img>
           </div>

           <v-card width="600" height="400" class="pa-8">
             <v-card-text class="ml-15 text-left pa-5" >
               <div>¿Quienes somos?</div>
               <p class="text-h4 text--primary">
                 Bussines Capital Services
               </p>
               <p class="text-justify text--primary mr-4 pa-5">
                 Bussines Capital SAPI de CV, es una innovadora solución de financiación alternativa online para pymes, que reúne a pequeñas y medianas empresas interesadas en financiar sus proyectos, con inversores que buscan diversificar su cartera de renta variable a través de inversiones de bajo riesgo y asi impulsar el nacimiento, desarrollo y expansión de empresas de alto impacto en México.
               </p>
            
             </v-card-text>
            
           </v-card>
         </div>
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid class="identity-section pa-7">
      <v-row class="d-flex justify-space-around">
        <v-col cols="12" md="12">
          <Identity/>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-row>
        <v-col v-for="(ds, i) in services" :key="i" cols="12" md="4" xs="12">
          <CardService :data="ds" :showDescription="false"/>
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid class="section-bg1">
      <CardVideo/>
    </v-container>
    <v-container fluid class="mt-5 pa-7">
      <Projects/>
    </v-container>
  </div>
</template>

<script>
// @ is an alias to /src
import { Identity, CardService, ProfileClient, Projects, Carousel, CardVideo } from "@/components";

export default {
  name: "HomeView",
  components: {
    Identity,
    CardService,
    ProfileClient,
    Projects,
    Carousel,
    CardVideo
},
  data: () => ({
    services: [
      {
        id: 1,
        title: "Éxito",
        description: "Nuestro éxito reside en nuestra amplia experiencia con el sector Financiero y el Servicio Integral de apoyo en el proceso y obtención de tu inversión.",
      },
      {
        id: 2,
        title: "Garantía",
        description: "Nuestro principios fundamentales estan regidos por la legalidad, la transparencia y lealtad con cada uno de nuestros clientes que confian ampliamente en nosotros.",
      },
      {
        id: 4,
        title: "Innovación",
        description: "La clave de nuestra iniciativa está en la innovación, convirtiendo a Bussines Capital en una solución fuera de los patrones tradicionales de financiación. Como emprendedores, estamos familiarizados con las dificultades que enfrentan las PYMES cuando buscan acceder al capital de trabajo necesario de los bancos.",
      }
    ],
  }),
};
</script>
<style scoped>
@import url("@/assets/css/style.css");

.site-heading,
.site-heading-black {
  margin-bottom: 30px;
  text-align: center;
}
.section-who{
  background-color: #fff;
  padding: 5px;
}
.container-bsc-who{
  position: relative;
  width: 100%;

}
.img-container-who{
  z-index: 1;
}
.img-who{
  box-shadow: 0px 10px 30px 2px rgba(0, 0, 0, 0.15);
}

.identity-section{
  background: rgba(0, 0, 0, 0) url("@/assets/img/bg/4.jpg") no-repeat fixed 0 0 / cover;
  position: relative;
  text-align: center;
  color: #fff !important;
}

.identity-section:before {
  background: rgba(1, 7, 23, 0.78) none repeat scroll 0 0;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.section-bg1{
  background: rgb(0, 0, 0) none repeat scroll 0 0;
  color: #fff !important;
}
</style>
