import Vue from 'vue'
import Vuex from 'vuex'

import { web_page } from './modules/app.module'
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    web_page
  }
})
